import React from "react";
import Seo from "../components/seo";
import Header from "../components/header/Header";
import ImgImani from "../images/links-imani-03.png";
import ImgTeta1 from "../images/links-tera-01.png";
import ImgTeta2 from "../images/links-tera-02.png";
import ImgIDream from "../images/links-dream-01.png";
import CommonDoc from "../components/CommonDoc";
import CommonDocProfile from "../components/CommonDocProfile";

// markup
const AboutPage = (props) => {
  return (
    <main>
      <Seo lang={`ja`} title={`関連情報`} />

      <div>
        <Header lang={`ja`} />
      </div>

      <CommonDoc>
        <h2>関連情報</h2>
        <p>
          アーティスト・イン・レジデンスプログラム”Liminal
          Space－リミナル・スペース”と、本サイトにおいて、多層的な視点を与えた人や作品、ページを紹介します。
        </p>
        <hr/>
        <section>
          <h3>ブレア・イマニ「なぜ女性史月間があるの？」</h3>
          <p>
            テレサ・フェルドマンは、パンデミックで世界が混乱し、自身も不安に巻き込まれる傍ら、イマニのインスタグラムやYouTubeで発信される短い動画に学び、ソーシャルメディアで共有されるさまざまな「声」に耳を傾けた。
            イマニの<a href={`https://www.youtube.com/@blairimani`} target={`_blank`}>チャンネル</a>では、現代社会において重要なトピックを幅広く、軽快に取り上げている。
          </p>
          <figure>
            <img src={ImgImani} alt="" />
          </figure>
          <CommonDocProfile>
            <h4>ブレア・イマニ</h4>
            <p>
              アメリカ・ロサンゼルス在住。インターセクショナリティ（交差性）やジェンダー学、人種・人種主義、社会学、アメリカ史に関心を寄せ、作家、エデュケーター、歴史家、インフルエンサーとして領域を横断する活動を行う。女性や黒人のグローバルなコミュニティ、LGBTQコミュニティを軸に、あらゆる声を増幅させ、困難に立ち向かう人々に寄り添っている。InstagramやYouTubeで情報発信するほか、オックスフォード、スタンフォード、ハーバード、デューク大学などでも発表を行う。
            </p>
            <p>
              これまでの著書に『Making Our Way Home, and Modern
              HERstory』のほか、近著である『Read This To Get Smarter: about
              Race, Class, Gender, Disability & More』（Ten Speed
              Press、2021）はLA
              Timesに「ベスト・セリング・オーサー」として紹介された。イマニの世界観にThe
              New York
              Timesは「生き生きとしたビジュアルを備えた革新的なレッスンは、意気揚々と独特なスタイルを持っている」と称した。ムスリム文化をアメリカや世界に向けて発信するMuslims
              For Progressive Values評議委員。
            </p>
            <p>
              <a href={`https://blairimani.com/`} target={`_blank`}>
                ウェブサイト
              </a>
              、
              <a
                href={`https://www.instagram.com/blairimani/`}
                target={`_blank`}
              >
                Instagram
              </a>
            </p>
          </CommonDocProfile>
        </section>

        <hr/>

        <section>
          <h3>寺田健人</h3>
          <p>
            本プロジェクトでクラウドソーシングを通しリライトを依頼した時、「家族像」に関する質問を含めたのは、社会の要請と個々の間にある揺らぎを探るものだった。寺田はプログラムには参加アーティストとして関与していないが、寺田自身が登場する写真作品が内在さえる語りは、観る者に自由な「家族像」の余白を与えている。寺田はグループ展「人間臭さを勝ち取る為の実践」（2021年4月〜5月、Alt_Medium、東京）への参加に際して、関連トーク「<a href={`https://www.youtube.com/watch?v=6D2vYOUi_Ew`} target={`_blank`}>これからの家族のあり方について～家族法の観点から～</a>」にもリサーチャーの松田和樹と同展参加アーティストのパク・サンヒョンと出演している。
          </p>
          <figure>
            <img src={ImgTeta1} alt="" />
            <figcaption>
              「ケーキ、妻、娘」、2020、アーカイバルピグメントプリント、508×610mm、©寺田健人
            </figcaption>
          </figure>
          <figure>
            <img src={ImgTeta2} alt="" />
            <figcaption>
              「公園、娘」、2021、アーカイバルピグメントプリント、508×610mm、©寺田健人
            </figcaption>
          </figure>
          <CommonDocProfile>
            <h4>寺田健人</h4>
            <p>
            1991年沖縄県生まれ。2017年沖縄県立芸術大学芸術学専攻卒業後、2019年東京藝術大学大学院美術研究科先端芸術表現専攻修了。2021年現在、横浜国立大学大学院都市イノベーション学府後期博士課程在籍。{" "}
            <br />
            社会が作り出した「性」や「生まれ」に関する諸規範によって人々の行動・思考が決定されていく生政治（バイオポリティクス）に関心を持ち、ラディカル・フェミニズムが生み出した「個人的なことは政治的なこと」の実践として、主にパフォーマンスと写真を軸にして制作を行なっている。
            </p>
          </CommonDocProfile>
        </section>
        <hr/>

        <section>
          <h3>i dream of covid</h3>
          <figure>
            <img src={ImgIDream} alt="" />
          </figure>
          <p><a href={`https://www.idreamofcovid.com`} target={`_blank`}>www.idreamofcovid.com</a></p>
        </section>
      </CommonDoc>
    </main>
  );
};

export default AboutPage;
